export const Free_amount = "$0";
export const Premium_amount = "$9";
export const TextFree_limit = "Text limit : 5";
export const TextPremium_limit = "Text limit : 100";
export const ImageFree_limit = "Image limit : 2";
export const ImagePremium_limit = "Image limit : 50";
export const Android_link = "https://play.google.com/store/apps/details?id=com.kar1mmohamed.adbot";
export const Ios_link = "https://play.google.com/store/apps/details?id=com.kar1mmohamed.adbot";


export const Free = () => {
	return (
        <svg width="70" height="70" viewBox="0 0 2200 2200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M504.167 366.667V206.25M870.834 366.667V206.25M1237.5 366.667V206.25M183.333 1100H1605.08M1630.75 959.75V1630.75C1630.75 1733.1 1590.09 1831.26 1517.72 1903.63C1445.34 1976.01 1347.19 2016.67 1244.83 2016.67H569.25C356.584 2016.67 183.333 1843.42 183.333 1630.75V959.75C183.333 857.399 223.992 759.239 296.366 686.866C368.739 614.492 466.899 573.833 569.25 573.833H1244.83C1457.5 573.833 1630.75 747.083 1630.75 959.75ZM2016.67 1206.33C2016.67 1419 1843.42 1592.25 1630.75 1592.25V820.417C1681.43 820.417 1731.61 830.399 1778.43 849.793C1825.26 869.187 1867.8 897.613 1903.63 933.449C1939.47 969.285 1967.9 1011.83 1987.29 1058.65C2006.68 1105.47 2016.67 1155.65 2016.67 1206.33Z" stroke="#4795EA" strokeWidth="80" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
	);
};

export const Premium = () => {
	return (
        <svg width="70" height="70" viewBox="0 0 2200 2200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M595.833 2016.67H1604.17M870.833 1283.33H1329.17M1530.83 1739.83H669.167C630.667 1739.83 587.583 1709.58 574.75 1672.92L195.25 611.416C141.167 459.25 204.417 412.5 334.583 506L692.083 761.75C751.667 803 819.5 781.916 845.167 715L1006.5 285.083C1057.83 147.583 1143.08 147.583 1194.42 285.083L1355.75 715C1381.42 781.916 1449.25 803 1507.92 761.75L1843.42 522.5C1986.42 419.833 2055.17 472.083 1996.5 638L1626.17 1674.75C1612.42 1709.58 1569.33 1739.83 1530.83 1739.83Z" stroke="#4795EA" strokeWidth="80" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
	);
};

export const Facebook = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10C0 14.84 3.44 18.87 8 19.8V13H6V10H8V7.5C8 5.57 9.57 4 11.5 4H14V7H12C11.45 7 11 7.45 11 8V10H14V13H11V19.95C16.05 19.45 20 15.19 20 10Z" fill="#bad9fc80"/>
        </svg>
	);
};

export const Instagram = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 0.25H5.5C4.10807 0.251489 2.77358 0.805091 1.78933 1.78933C0.805091 2.77358 0.251489 4.10807 0.25 5.5V14.5C0.251489 15.8919 0.805091 17.2264 1.78933 18.2107C2.77358 19.1949 4.10807 19.7485 5.5 19.75H14.5C15.8919 19.7485 17.2264 19.1949 18.2107 18.2107C19.1949 17.2264 19.7485 15.8919 19.75 14.5V5.5C19.7485 4.10807 19.1949 2.77358 18.2107 1.78933C17.2264 0.805091 15.8919 0.251489 14.5 0.25ZM10 14.5C9.10998 14.5 8.23995 14.2361 7.49993 13.7416C6.75991 13.2471 6.18314 12.5443 5.84254 11.7221C5.50195 10.8998 5.41283 9.99501 5.58647 9.12209C5.7601 8.24918 6.18868 7.44736 6.81802 6.81802C7.44736 6.18868 8.24918 5.7601 9.12209 5.58647C9.99501 5.41283 10.8998 5.50195 11.7221 5.84254C12.5443 6.18314 13.2471 6.75991 13.7416 7.49993C14.2361 8.23995 14.5 9.10998 14.5 10C14.4988 11.1931 14.0243 12.337 13.1806 13.1806C12.337 14.0243 11.1931 14.4988 10 14.5ZM15.625 5.5C15.4025 5.5 15.185 5.43402 15 5.3104C14.815 5.18679 14.6708 5.01109 14.5856 4.80552C14.5005 4.59995 14.4782 4.37375 14.5216 4.15552C14.565 3.93729 14.6722 3.73684 14.8295 3.5795C14.9868 3.42217 15.1873 3.31502 15.4055 3.27162C15.6238 3.22821 15.85 3.25049 16.0555 3.33564C16.2611 3.42078 16.4368 3.56498 16.5604 3.74998C16.684 3.93499 16.75 4.1525 16.75 4.375C16.75 4.67337 16.6315 4.95952 16.4205 5.1705C16.2095 5.38147 15.9234 5.5 15.625 5.5ZM13 10C13 10.5933 12.8241 11.1734 12.4944 11.6667C12.1648 12.1601 11.6962 12.5446 11.1481 12.7716C10.5999 12.9987 9.99667 13.0581 9.41473 12.9424C8.83279 12.8266 8.29824 12.5409 7.87868 12.1213C7.45912 11.7018 7.1734 11.1672 7.05764 10.5853C6.94189 10.0033 7.0013 9.40013 7.22836 8.85195C7.45542 8.30377 7.83994 7.83524 8.33329 7.50559C8.82664 7.17595 9.40666 7 10 7C10.7956 7 11.5587 7.31607 12.1213 7.87868C12.6839 8.44129 13 9.20435 13 10Z" fill="#bad9fc80"/>
        </svg>
	);
};

export const Twitter = () => {
	return (
		<svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.46 2C20.69 2.35 19.86 2.58 19 2.69C19.88 2.16 20.56 1.32 20.88 0.31C20.05 0.81 19.13 1.16 18.16 1.36C17.37 0.5 16.26 0 15 0C12.65 0 10.73 1.92 10.73 4.29C10.73 4.63 10.77 4.96 10.84 5.27C7.27998 5.09 4.10998 3.38 1.99998 0.79C1.62998 1.42 1.41998 2.16 1.41998 2.94C1.41998 4.43 2.16998 5.75 3.32998 6.5C2.61998 6.5 1.95998 6.3 1.37998 6V6.03C1.37998 8.11 2.85998 9.85 4.81998 10.24C4.19071 10.4122 3.53007 10.4362 2.88998 10.31C3.16158 11.1625 3.69351 11.9084 4.41099 12.4429C5.12847 12.9775 5.99543 13.2737 6.88998 13.29C5.37361 14.4904 3.49397 15.1393 1.55998 15.13C1.21998 15.13 0.879978 15.11 0.539978 15.07C2.43998 16.29 4.69998 17 7.11998 17C15 17 19.33 10.46 19.33 4.79C19.33 4.6 19.33 4.42 19.32 4.23C20.16 3.63 20.88 2.87 21.46 2Z" fill="#bad9fc80"/>
        </svg>
	);
};

export const Github = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 14.42 2.87 18.17 6.84 19.5C7.34 19.58 7.5 19.27 7.5 19V17.31C4.73 17.91 4.14 15.97 4.14 15.97C3.68 14.81 3.03 14.5 3.03 14.5C2.12 13.88 3.1 13.9 3.1 13.9C4.1 13.97 4.63 14.93 4.63 14.93C5.5 16.45 6.97 16 7.54 15.76C7.63 15.11 7.89 14.67 8.17 14.42C5.95 14.17 3.62 13.31 3.62 9.5C3.62 8.39 4 7.5 4.65 6.79C4.55 6.54 4.2 5.5 4.75 4.15C4.75 4.15 5.59 3.88 7.5 5.17C8.29 4.95 9.15 4.84 10 4.84C10.85 4.84 11.71 4.95 12.5 5.17C14.41 3.88 15.25 4.15 15.25 4.15C15.8 5.5 15.45 6.54 15.35 6.79C16 7.5 16.38 8.39 16.38 9.5C16.38 13.32 14.04 14.16 11.81 14.41C12.17 14.72 12.5 15.33 12.5 16.26V19C12.5 19.27 12.66 19.59 13.17 19.5C17.14 18.16 20 14.42 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z" fill="#bad9fc80"/>
        </svg>
	);
};

export const Dribble = () => {
	return (
		<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_173_126" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
                <path d="M21 11C21.0017 12.3172 20.7424 13.6216 20.237 14.8379C19.7316 16.0543 18.9901 17.1584 18.0555 18.0865C17.1285 19.0118 16.0283 19.7454 14.8177 20.2454C13.6071 20.7453 12.3098 21.0017 11 21C5.47701 21 1.00001 16.523 1.00001 11C0.996513 8.43589 1.98144 5.96909 3.75001 4.11251C4.68318 3.12781 5.80747 2.34388 7.05407 1.8087C8.30068 1.27352 9.64338 0.998342 11 1.00001C12.3098 0.998312 13.6071 1.25475 14.8177 1.75466C16.0283 2.25458 17.1285 2.98817 18.0555 3.91351C18.9901 4.84162 19.7316 5.94575 20.237 7.16208C20.7424 8.37842 21.0017 9.68285 21 11Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 10.9998C19.541 10.9998 15.516 10.4498 11.9135 12.0313C8.00002 13.7498 5.16652 16.4153 3.93152 18.0733" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.25 1.72656C8.815 3.17106 12.23 6.84856 13.5 10.4996C14.77 14.1506 15.24 18.6396 15.53 19.9171" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.07703 9.75035C2.96603 9.86435 7.96653 9.96685 11.1665 8.60035C14.3665 7.23385 17.12 4.72035 18.0645 3.92285" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.75001 14.8065C2.63117 16.9395 4.22036 18.7036 6.25001 19.802M1.00001 11C0.996513 8.43589 1.98144 5.96909 3.75001 4.11251C4.68318 3.12781 5.80747 2.34388 7.05407 1.8087C8.30068 1.27352 9.64338 0.998341 11 1.00001M15 1.83201C16.1388 2.33028 17.175 3.03613 18.0555 3.91351C18.9901 4.84162 19.7316 5.94574 20.237 7.16208C20.7424 8.37842 21.0017 9.68285 21 11C21 12.231 20.7775 13.4105 20.37 14.5M11 21C12.3098 21.0017 13.6071 20.7453 14.8177 20.2454C16.0283 19.7454 17.1285 19.0118 18.0555 18.0865" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </mask>
            <g mask="url(#mask0_173_126)">
                <path d="M-1 -1H23V23H-1V-1Z" fill="#bad9fc80"/>
            </g>
        </svg>
	);
};